import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {NavLink} from "react-router-dom"
import logo from "../../assets/img/papel-logo.svg"
import "./Navbar.css"
import ig from "../../assets/img/ig.svg"
import github from "../../assets/img/github.svg"
import linkedin from "../../assets/img/linkedin.svg"


function BasicExample() {

    const [navbar, setNavbar] = useState(false)
    
    const changeBackground = ()=>{
      if(window.scrollY >= 40){
        setNavbar(true)
      }else{
        setNavbar(false)
      }
    }

    window.addEventListener("scroll", changeBackground);

  return (
    
    <Navbar className={navbar ? "navbar active" : "navbar"} sticky="top" expand="lg">
      <Container>
        <Navbar.Brand>
            <NavLink to={"/"}>
                <img className='logo-nav' src={logo} alt="logo from designer"></img>
            </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto d-flex basis justify-content-end">
              <a href="https://www.instagram.com/papelfloreado/" target="_blank" rel="noopener noreferrer">
                  <img className='w-50 width-res' src={ig} alt="instagram logo"></img>
              </a>
              <a href="https://github.com/PapelFloreado" target="_blank" rel="noopener noreferrer">
                  <img className='w-50 width-res' alt="github logo" src={github}></img>
              </a>
              <a href="https://github.com/PapelFloreado" target="_blank" rel="noopener noreferrer">
                  <img className='w-50 width-res' alt="linkedin logo" src={linkedin}></img>
              </a>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;