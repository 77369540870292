import React,{useEffect} from 'react'
import { Container } from 'react-bootstrap'
import { useInView } from 'react-intersection-observer'
import Carousel from "../Carousel/Carousel"
import "./Techs.css"
import { useAnimation, motion } from 'framer-motion'


const Techs = () => {

  const {ref, inView} = useInView({threshold:0.3})
  const animation = useAnimation()
  
  useEffect(() => {
    if(inView){
      animation.start({
        opacity:1,
        transition:{
          ease:"easeInOut",
          duration:1,
          delay:0.1,}
      })
    }if(!inView){
      animation.start({
        opacity:0,
        
      })
    }
  
  }, [inView, animation])
  

  return (
        <div ref={ref} className='techs-back'>
            <Container>
                <motion.h2 animate={animation} className='tech-title'>.techs I work with</motion.h2>
                <motion.div animate={animation}>
                    <Carousel />
                </motion.div>
            </Container>
        </div>
  )
}

export default Techs