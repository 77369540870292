import logo1 from "../../assets/img/logo-react.svg"
import logo2 from "../../assets/img/git.svg"
import logo3 from "../../assets/img/bootstrap-fill.svg"
import logo4 from "../../assets/img/html5.svg"
import logo5 from "../../assets/img/css.svg"
import logo6 from "../../assets/img/sass.svg"
import logo7 from "../../assets/img/framer.svg"
import logo8 from "../../assets/img/logo-javascript.svg"
import React from "react"
import { motion } from "framer-motion";
import "./Carousel.css"


function DarkVariantExample() {
 
  return (
    <div className='d-flex justify-content-between align-items-center pt-5 pb-5 flex-wrap  basis'>
      <motion.div whileHover={{ scale: 1.1}}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}className="icon" >
        <img  className="desvanecer" src={logo2} alt="git logo"/>
        <div className="ocult fs-5 pt-4">GIT</div>
      </motion.div>
      <motion.div whileHover={{ scale: 1.1 }}
                  stransition={{ type: "spring", stiffness: 400, damping: 10 }} className="icon">
        <img src={logo3} alt="bootstrap logo"/>
        <div className="ocult fs-5 pt-4">Bootstrap</div>
      </motion.div>
      <motion.div whileHover={{ scale: 1.1 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }} className="icon">
        <img  src={logo4} alt="hmtl5 logo"/>
        <div className="ocult fs-5 pt-4">HTML5</div>
      </motion.div>
      <motion.div whileHover={{ scale: 1.1 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }} className="icon">
        <img src={logo1} alt="react logo"/>
        <div className="ocult fs-5 pt-4">React Js</div>
      </motion.div>
      <motion.div whileHover={{ scale: 1.1 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }} className="icon">
        <img src={logo8} alt="js logo"/>
        <div className="ocult fs-5 pt-4">Javascript</div>
      </motion.div>
      <motion.div className="icon" whileHover={{ scale: 1.1 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }} >
        <img src={logo7} alt="framer motion logo"/>
        <div className="ocult fs-5 pt-4">Framer Motion</div>
      </motion.div>
      <motion.div className="icon" whileHover={{ scale: 1.1 }}
                  transition={{ type: "spring", stiffness: 400, damping: 10 }}>
        <img  src={logo5} alt="css3 logo"/>
        <div className="ocult fs-5 pt-4">CSS3</div>
      </motion.div>
      <motion.div whileHover={{ scale: 1.1 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }} className="icon">
        <img src={logo6} alt="sass logo"/>
      <div className="ocult fs-5 pt-4">Sass</div>
      </motion.div>
    </div>
   
  );
}

export default DarkVariantExample;