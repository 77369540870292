import React, { useRef, useState } from 'react';
import { Container, Form } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import "./Form.css"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import SpinnerLoad from "../SpinnerLoad/SpinnerLoad"


const ContactUs = () => {
    const form = useRef();
    const MySwal = withReactContent(Swal)
    const [loading, setLoading] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true)
    emailjs.sendForm('portfolio', 'template_jbkow4u', form.current, 'LKJwkPHoxjygpIh75')
      .then((result) => {
            
            console.log(result.text);
            MySwal.fire({
                title: <strong>Thanks a lot!</strong>,
                html:(
                    <div>
                        <h3>We will contact you very soon.</h3>
                    </div>
                )
                })
    setLoading(false)
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };

  return (
    
    <div className='form  p-5 d-flex justify-content-center'>
        <Container className='col-12'>
            <h2 className='fs-1'>.contact me</h2>
            <Form ref={form} onSubmit={sendEmail}>
                <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" name="name" placeholder="Name" required/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name="email" placeholder="Email" required/>   
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Your Message</Form.Label>
                    <Form.Control name="message" as="textarea" rows={3} />
                </Form.Group>
                <div className='d-flex justify-content-center align-items-center'>
                <input className='btn'  value="Send Email!" type="submit"/>

                {
                    loading === true ? (<SpinnerLoad/>) : (<></>)
                }
                </div>
            </Form>
        </Container>
    </div>
  );
};
export default ContactUs