import React, {useEffect} from 'react'
import "./Banner.css"
import { Container } from 'react-bootstrap'
import Form from "../Form/Form"
import { motion,useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const Banner = () => {
  const {ref, inView} = useInView({threshold:0.3})
  const animation = useAnimation()
  
  useEffect(() => {
    if(inView){
      animation.start({
        opacity:1,
        transition:{
          ease:"easeInOut",
          duration:1,
          delay:0.1,}
      })
    }if(!inView){
      animation.start({
        opacity:0,
        
      })
    }
  
  }, [inView, animation])
  return (
    <div ref={ref} className='banner pb-5'>
        <Container>
            <motion.div animate={animation} className='icon-banner'>
                <motion.h2  whileHover={{ scale: 1.1}}
                            transition={{
                              scale: { ease: "easeInOut" },
                              }}
                className='banner-title1'>.be creative</motion.h2>
                <motion.p animate={{ opacity: 0 }}
                          whileHover={{ scale: 1, opacity: 1}}
                          transition={{
                            opacity: { ease: "easeInOut" },
                            }} 
                          className='text-ocult row fs-1'>"Imagination is the beginning of creation."</motion.p>
            </motion.div>
            <motion.div ref={ref} animate={animation} className='icon-banner'>
                <motion.p  animate={{ opacity: 0 }}
                          whileHover={{ scale: 1, opacity: 1}}
                          transition={{
                            opacity: { ease: "easeInOut" },
                            }}
                          className='text-ocult fs-1'>"Let's not forget that the little emotions are the great captains of our lives and we obey them without realizing it."</motion.p>
                <motion.h2 whileHover={{ scale: 1.1}}
                            transition={{
                              scale: { ease: "easeInOut" },
                              }} className='banner-title2 '>.be smart</motion.h2>
            </motion.div>
            <motion.div ref={ref} animate={animation} className='col-12 col-sm-12 col-md-12 d-flex justify-content-center'>
              <Form/>
            </motion.div> 
        </Container>
    </div>
  )
}

export default Banner