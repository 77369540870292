import React from 'react'
import About from '../About/About'
import Banner from '../Banner/Banner'
import Footer from '../Footer/Footer'
import Techs from '../Techs/Techs'
import Works from '../Works/Works'



const Home = () => {
  return (
    <>
        <About/>
        <Techs/>
        <Works/>
        <Banner/>
        <Footer/>
    </>
  )
}

export default Home