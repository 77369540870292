import React from 'react'
import {BrowserRouter, Routes, Route} from "react-router-dom"
import Navbar from "../components/Navbar/Navbar"
import Home from "../components/Home/Home"


const Rutas = () => {


  return (
    <BrowserRouter>
        <Navbar/>
        <Routes>
            <Route path="/" element={<Home/>}/>
        </Routes>
    </BrowserRouter>
  )
}

export default Rutas