import React,{useEffect} from 'react'
import { Container, Card, NavLink } from 'react-bootstrap'
import "./Works.css"
import ecommerce from "../../assets/img/web 1.jpg"
import insurance from "../../assets/img/web2.jpg"
import entreperros from "../../assets/img/web3.jpg"
import agencia from "../../assets/img/agencia de viajes.jpg"
import apv from "../../assets/img/apv.jpg"
import food from "../../assets/img/food.jpg"
import weather from "../../assets/img/weather.jpg"
import poke from "../../assets/img/poke.jpg"
import wedding from "../../assets/img/wedding.jpg"
import romigurumis from "../../assets/img/romigurumis.jpg"
import perros from "../../assets/img/entreperros-web.png"
import { useInView } from 'react-intersection-observer'
import { useAnimation, motion } from 'framer-motion'

const Works = () => {

  const {ref, inView} = useInView({threshold:0.1})
  const animation = useAnimation()
  
  useEffect(() => {
    if(inView){
      animation.start({
        opacity:1,
        transition:{
          ease:"easeInOut",
          duration:1,
          delay:0.1,}
      })
    }if(!inView){
      animation.start({
        opacity:0,
        
      })
    }
  
  }, [inView, animation])

  return (
    <Container className='pt-5 pb-5'>
        <div ref={ref} className='flex-wrap'>
            <motion.h2 animate={animation} className='works-title'>.some of my works</motion.h2>
            <motion.div animate={animation} className='d-flex justify-content-center flex-wrap'>
                <motion.div 
                            whileHover={{ scale: 1.2}}
                            transition={{
                              opacity: { ease: "easeInOut" },
                              }} className='p-5'>
                    <Card className='card-back' style={{ maxWidth: '30rem' }}>
                    <Card.Img variant="top" src={perros} alt="image of wedding invitation work portfolio"/>
                    <Card.Body>
                      <Card.Title>Restyled page for local rock band</Card.Title>
                      <Card.Text className='height'>
                      This website was made in NextJs 13 + Tailwind Css, SEO optimized. For local band project.                      </Card.Text>
                      <NavLink href="https://entreperros.com.ar" target="_blank" rel="noopener noreferrer">
                      <button className='btn'>Go to the site</button>
                      </NavLink>
                    </Card.Body>
                    </Card>
                </motion.div>
                <motion.div whileHover={{ scale: 1.2}}
                            transition={{
                            opacity: { ease: "easeInOut" },
                            }} className='p-5'>
                    <Card className='card-back' style={{ maxWidth: '30rem' }}>
                    <Card.Img variant="top" src={food} alt="image of recipy website portfolio example"/>
                    <Card.Body>
                      <Card.Title>React + Vite, Firebase, Tailwind</Card.Title>
                      <Card.Text className='height'>
                      Recepy App connect with Firebase Firestore, Google Auth and Storage. All the css made with Tailwind. To see the code, contact me.
                      </Card.Text>
                      <NavLink href="https://magnificent-kangaroo-e48c1f.netlify.app/"  target="_blank" rel="noopener noreferrer" >
                      <button className='btn'>Go to the site</button>
                      </NavLink>
                    </Card.Body>
                    </Card>
                </motion.div>
                
                <motion.div 
                            whileHover={{ scale: 1.2}}
                            transition={{
                              opacity: { ease: "easeInOut" },
                              }} className='p-5'>
                    <Card className='card-back' style={{ maxWidth: '30rem' }}>
                    <Card.Img variant="top" src={romigurumis} alt="image of wedding invitation work portfolio"/>
                    <Card.Body>
                      <Card.Title>E-Commerce of Romigurumis</Card.Title>
                      <Card.Text className='height'>
                      I built this site with ReactJs + vite for a local startup. This website is connected to the Google Firebase Database and MercadoPago.
                      </Card.Text>
                      <NavLink href="https://romigurumis-app.vercel.app/" target="_blank" rel="noopener noreferrer">
                      <button className='btn'>Go to the site</button>
                      </NavLink>
                    </Card.Body>
                    </Card>
                </motion.div>
                <motion.div 
                            whileHover={{ scale: 1.2}}
                            transition={{
                              opacity: { ease: "easeInOut" },
                              }} className='p-5'>
                    <Card className='card-back' style={{ maxWidth: '30rem' }}>
                    <Card.Img variant="top" src={ecommerce} alt="image of ecommerce work portfolio"/>
                    <Card.Body>
                      <Card.Title>Ecommerce</Card.Title>
                      <Card.Text className='height'>
                      I built this site with ReactJs for a local startup. This website is connected to the Google Firebase Database.
                      </Card.Text>
                      <NavLink href="https://ecommerce-cafe-peralta.vercel.app/" target="_blank" rel="noopener noreferrer">
                      <button className='btn'>Go to the site</button>
                      </NavLink>
                    </Card.Body>
                    </Card>
                </motion.div>
                <motion.div whileHover={{ scale: 1.2}}
                            transition={{
                            opacity: { ease: "easeInOut" },
                            }} className='p-5'>
                    <Card className='card-back' style={{ maxWidth: '30rem' }}>
                    <Card.Img variant="top" src={insurance} alt="image of insurance simulator work" />
                    <Card.Body>
                      <Card.Title>Insurance Simulator</Card.Title>
                      <Card.Text className='height'>
                      This site contains an insurance quote simulator and a shopping cart all done in Js.
                      </Card.Text>
                      <NavLink href="https://papelfloreado.github.io/simulador_peralta/" target="_blank" rel="noopener noreferrer">
                      <button className='btn'>Go to the site</button>
                      </NavLink>
                    </Card.Body>
                    </Card>
                </motion.div>
                <motion.div 
                            whileHover={{ scale: 1.2}}
                            transition={{
                              opacity: { ease: "easeInOut" },
                              }} className='p-5'>
                    <Card className='card-back' style={{ maxWidth: '30rem' }}>
                    <Card.Img variant="top" src={wedding} alt="image of wedding invitation work portfolio"/>
                    <Card.Body>
                      <Card.Title>Digital Web Invitation</Card.Title>
                      <Card.Text className='height'>
                      I built this site with react as an example of a digital web Invitation. Using Tailwinds Css and RactJs + Vite.
                      </Card.Text>
                      <NavLink href="https://wedding-example.vercel.app/" target="_blank" rel="noopener noreferrer">
                      <button className='btn'>Go to the site</button>
                      </NavLink>
                    </Card.Body>
                    </Card>
                </motion.div>
                
                <motion.div whileHover={{ scale: 1.2}}
                            transition={{
                            opacity: { ease: "easeInOut" },
                            }} className='p-5'>
                    <Card className='card-back' style={{ maxWidth: '30rem' }}>
                    <Card.Img variant="top" src={agencia} alt="image of travel website portfolio example"/>
                    <Card.Body>
                      <Card.Title>Travel Agency made with Express and Pug</Card.Title>
                      <Card.Text className='height'>
                      Site created with Express, Pug and Bootstrap. This site recreate a travel agency who has a testimonial form to add comments about the agency. And consult to a table of mysql about the travel offer in the site.
                      </Card.Text>
                      <NavLink href="https://gentle-bastion-35670.herokuapp.com/"  target="_blank" rel="noopener noreferrer" >
                      <button className='btn'>Go to the site</button>
                      </NavLink>
                    </Card.Body>
                    </Card>
                </motion.div>
                <motion.div whileHover={{ scale: 1.2}}
                            transition={{
                            opacity: { ease: "easeInOut" },
                            }} className='p-5'>
                    <Card className='card-back' style={{ maxWidth: '30rem' }}>
                    <Card.Img variant="top" src={apv} alt="image of travel website portfolio example"/>
                    <Card.Body>
                      <Card.Title>FullStack with Express, ReactJs and Tailwind</Card.Title>
                      <Card.Text className='height'>
                      Mern fullstack project built with MongoDB, Express and ReactJs. The deploy was done in heroku for the backend and the frontend in netlify. To see the code see my github link.
                      </Card.Text>
                      <NavLink href="https://nimble-cassata-b5ec35.netlify.app/"  target="_blank" rel="noopener noreferrer" >
                      <button className='btn'>Go to the site</button>
                      </NavLink>
                    </Card.Body>
                    </Card>
                </motion.div>
                <motion.div whileHover={{ scale: 1.2}}
                            transition={{
                            opacity: { ease: "easeInOut" },
                            }} className='p-5'>
                    <Card className='card-back' style={{ maxWidth: '30rem' }}>
                    <Card.Img variant="top" src={entreperros} alt="image of static website portfolio example"/>
                    <Card.Body>
                      <Card.Title>Static Site for Local Band</Card.Title>
                      <Card.Text className='height'>
                      Static site created for local band. Contains different sections. Made with html5 ccs3 bootstrap and sass
                      </Card.Text>
                      <NavLink href="https://entreperros.netlify.app/"  target="_blank" rel="noopener noreferrer" >
                      <button className='btn'>Go to the site</button>
                      </NavLink>
                    </Card.Body>
                    </Card>
                </motion.div>
                <motion.div whileHover={{ scale: 1.2}}
                            transition={{
                            opacity: { ease: "easeInOut" },
                            }} className='p-5'>
                    <Card className='card-back' style={{ maxWidth: '30rem' }}>
                    <Card.Img variant="top" src={weather} alt="image of weather app website portfolio example"/>
                    <Card.Body>
                      <Card.Title>React + Vite, OpenWeather API APP</Card.Title>
                      <Card.Text className='height'>
                      Weather App with a few cities to see. For this proyect i used OpenWeatherMap Org API to consume the data.
                      </Card.Text>
                      <NavLink href="https://weather-ck5z9n9wc-papelfloreado.vercel.app/"  target="_blank" rel="noopener noreferrer" >
                      <button className='btn'>Go to the site</button>
                      </NavLink>
                    </Card.Body>
                    </Card>
                </motion.div>
                <motion.div whileHover={{ scale: 1.2}}
                            transition={{
                            opacity: { ease: "easeInOut" },
                            }} className='p-5'>
                    <Card className='card-back' style={{ maxWidth: '30rem' }}>
                    <Card.Img variant="top" src={poke} alt="image of weather app website portfolio example"/>
                    <Card.Body>
                      <Card.Title>Poke App - React + Vite + Tailwind Css </Card.Title>
                      <Card.Text className='height'>
                      Small application to consult pokemons and their skills and base experience.
                      </Card.Text>
                      <NavLink href="https://pokedex-poke-api-app.vercel.app/"  target="_blank" rel="noopener noreferrer" >
                      <button className='btn'>Go to the site</button>
                      </NavLink>
                    </Card.Body>
                    </Card>
                </motion.div>
            </motion.div>
        </div>
    </Container>
  )
}

export default Works