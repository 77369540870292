import React from 'react'
import Container from 'react-bootstrap/Container';
import "./About.css"
import me from "../../assets/img/recurso-me.svg"
import { motion } from 'framer-motion';

const About = () => {
    return (
      <Container className='pt-4 about'>
          <motion.h1    initial={{opacity:0}}
                        animate={{opacity:1}}
                        transition={{duration:0.8, delay:0.4, ease:"easeInOut"}} 
                        className='about-header'>.when imagination begins</motion.h1>
          <motion.div   initial={{ opacity: 0, delay:1 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.8, delay: 0.8, ease:"easeInOut" }}
                        className='container-fluid container-responsive contenedor pt-5'>
              <img className='recurso-img' src={me} alt="me as a dev"></img>
              <div className='align-items-stretch pt-5'>
                  <p className='about-text pt-5 '>Hi!. I'm Gabriel Peralta AKA PapelFloreado. I'm a front-end Dev and I'll tell you a little more about what I do. Imagination is everything. From the daily life to the smallest detail can take you to another place. On this website you can find something that I am talking about. a unique product customized to the needs and measurements of the client.</p>
                  <p className='about-text '>If you can imagine it, it can be created.</p>
                  <p className='about-text '>You're not alone. Let's walk that path where the limit does not exist</p>
              </div>
          </motion.div>
      </Container>
  )
}

export default About